import lioshutanApi from '@/api';

const afterHookRouterGuardian = (to, from, next) => {
  // if (!to.redirectedFrom || !(to.redirectedFrom.split('/')[1] === 'hook')) {
  //   next({
  //     path: '/404',
  //   });
  // }

  next();
};
// 進入畫面前先檢查有沒有 token，沒有就導回首頁並打開登入介面彈窗
const checkGetToken = (to, from, next) => {
  const token = localStorage.getItem('wuwow_token');
  if (token) {
    next();
  } else {
    next({
      path: '/',
      query: { login: true }, // 提供此參數讓首頁知道要打開登入介面彈窗
    });
  }
};

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  // {
  //   path: '/reviews',
  //   name: 'summerCampReviews',
  //   component: () => import('@/views/dojo/summerCamp/Reviews.vue'),
  // },
  // for 在首頁開啟家長註冊 modal 用
  {
    path: '/register-parent-account',
    name: 'register-parent-account',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('@/views/home/thankYou/index.vue'),
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage'),
    hidden: true,
  },
  {
    path: '/privacy',
    component: () => import('@/views/privacy'),
    hidden: true,
  },
  {
    path: '/hook/:type',
    redirect: (to) => {
      console.log(to.params.type);
      return {
        path: `/${to.params.type}`,
      };
    },
  },
  {
    path: '/change-password',
    beforeEnter: afterHookRouterGuardian,
    component: () => import('@/views/hookPage/changePassword'),
  },
  {
    path: '/verifyMail',
    beforeEnter: afterHookRouterGuardian,
    component: () => import('@/views/hookPage/verifyMail'),
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: checkGetToken,
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/parent',
    beforeEnter: checkGetToken,
    component: () => import('@/views/parentPage'),
  },
  {
    path: '/dojo',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/dojo/notification/:type/:id',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/notification/type/id'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/dojo/classRoom',
    name: 'class-room',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/classRoom'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/dojo/evaluations',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/evaluations'),
    hidden: true,
  },
  {
    path: '/dojo/evaluations/viewAnalysis',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/evaluations/viewAnalysis'),
    name: 'viewAnalysis',
    hidden: true,
  },
  {
    path: '/dojo/evaluations/addEvaluation',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/evaluations/addEvaluation'),
    name: 'addEvaluation',
    hidden: true,
  },
  {
    path: '/dojo/book_class',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/bookClass'),
    hidden: true,
  },
  {
    path: '/dojo/bookClassRecord',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/bookClassRecord'),
    hidden: true,
  },
  {
    path: '/dojo/bookClass/requireConsultant',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/bookClass/requireConsultant'),
    name: 'requireConsultant',
    hidden: true,
  },
  {
    path: '/dojo/bookClass/requireConsultant/schedule/:hbConsultantId',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/bookClass/requireConsultant/schedule'),
    name: 'requireConsultantSchedule',
    hidden: true,
  },
  {
    path: '/dojo/classRecords',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/classRecords'),
    name: 'classRecords',
    hidden: true,
  },
  {
    path: '/dojo/classRecords/viewEvaluation',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/classRecords/viewEvaluation'),
    name: 'viewEvaluation',
    hidden: true,
  },
  {
    path: '/dojo/consultants',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/consultants'),
    name: 'consultants',
    hidden: true,
  },
  {
    path: '/dojo/consultants/:id',
    beforeEnter: checkGetToken,
    component: () => import('@/components/vip/consultantList/detail'),
    name: 'ConsultantDetail',
    hidden: true,
  },
  {
    path: '/dojo/consultants/:id/schedule',
    beforeEnter: checkGetToken,
    component: () => import('@/components/vip/consultantList/schedule'),
    name: 'ConsultantScheduleView',
    hidden: true,
  },
  {
    path: '/dojo/upgradeTest',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/upgradeTest'),
    name: 'UpgradeTest',
    hidden: true,
  },
  {
    path: '/dojo/upgradeTest/ViewExplain',
    beforeEnter: checkGetToken,
    component: () => import('@/components/vip/upgradeTest/viewExplain'),
    name: 'ViewExplain',
    hidden: true,
  },
  {
    path: '/dojo/upgradeTest/ViewExplain/innerPage',
    beforeEnter: checkGetToken,
    component: () => import('@/components/vip/upgradeTest/viewExplain/innerPage'),
    name: 'InnerPage',
    hidden: true,
  },
  {
    path: '/dojo/account/progressAnalysis',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/progressAnalysis'),
    hidden: true,
  },
  {
    path: '/dojo/account/contracts',
    name: 'contract-list-view',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/contracts'),
    hidden: true,
  },
  {
    path: '/dojo/account/contracts/learn_rule',
    name: 'learn-rule-view',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/contracts/learnRule'),
    hidden: true,
    beforeRouteEnter(to, from, next) {
      if (to.params.data) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/dojo/account/profile',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/profile'),
    hidden: true,
  },
  {
    path: '/dojo/account/profile/edit',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/profile/edit'),
    hidden: true,
  },
  {
    path: '/dojo/parents/profile',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/parents/profile'),
    hidden: true,
  },
  {
    path: '/dojo/parents/profile/edit',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/parents/profile/edit'),
    hidden: true,
  },
  {
    path: '/dojo/notification',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/notification'),
    hidden: true,
  },
  {
    path: '/dojo/faq',
    name: 'frequently-answer-question',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/faq'),
    hidden: true,
  },
  {
    path: '/dojo/account/selfTest',
    name: 'selfTest',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/selfTest'),
    hidden: true,
  },
  {
    path: '/dojo/account/system-testing-url',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/systemTestingUrl'),
    hidden: true,
  },
  {
    path: '/dojo/coupon',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/coupon'),
    hidden: true,
  },
  {
    path: '/dojo/coupon/couponClass/:coupon_id',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/coupon/couponClass/coupon_id'),
    hidden: true,
  },
  {
    path: '/dojo/book_now_class',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/book_now_class'),
    hidden: true,
  },
  {
    path: '/dojo/academy_talks',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/academy_talks'),
    hidden: true,
  },
  {
    path: '/dojo/account/suitable',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/account/suitable'),
    hidden: true,
  },
  {
    path: '/dojo/vip_mgm',
    name: 'vip-mgm',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/vip_mgm'),
    hidden: true,
  },
  {
    path: '/dojo/guide',
    name: 'guide',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/guide'),
    hidden: true,
  },
  {
    path: '/console/profiles',
    name: 'center-profile',
    component: () => import('@/views/console/profiles'),
    hidden: true,
  },
  {
    path: '/console/classroom',
    name: 'center-classroom',
    component: () => import('@/views/console/classroom'),
    hidden: true,
  },
  {
    path: '/console/classroom/vip-requests',
    name: 'vip-requests-classroom',
    component: () => import('@/views/console/classroom/vipRequests'),
    hidden: true,
  },
  {
    path: '/dojo/wushare/coupon_list',
    name: 'wushare-coupon-list',
    component: () => import('@/views/dojo/wushare/coupon_list'),
    hidden: true,
  },
  {
    path: '/console/materials',
    name: 'center-materials',
    component: () => import('@/views/console/materials'),
    hidden: true,
  },
  {
    path: '/console/centerManagement',
    component: () => import('@/views/console/centerManagement'),
    hidden: true,
  },
  {
    path: '/console/centerManagement/viewSchedule/:id',
    name: 'center-consultant-schedule',
    component: () => import('@/views/console/centerManagement/viewSchedule/index.vue'),
    hidden: true,
  },
  {
    path: '/console/consultant/schedule',
    name: 'consultant-schedule',
    component: () => import('@/views/console/consultant/schedule/viewSchedule/index.vue'),
    hidden: true,
  },
  {
    path: '/console/consultant/schedule/fixed',
    name: 'consultant-fixed-schedule',
    component: () => import('@/views/console/consultant/schedule/fixed/index.vue'),
    hidden: true,
  },
  {
    path: '/dojo/schedule/fixed',
    name: 'vip-fixed-schedule',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/scheduleFixed/index.vue'),
    hidden: true,
  },
  // {
  //   path: '/dojo/summer-camp',
  //   name: 'summerCamp',
  //   beforeEnter: checkGetToken,
  //   component: () => import('@/views/dojo/summerCamp/index.vue'),
  //   hidden: true,
  // },
  // {
  //   path: '/dojo/summer-camp/book-class',
  //   name: 'summerCampBookClass',
  //   beforeEnter: checkGetToken,
  //   component: () => import('@/views/dojo/summerCamp/BookClass.vue'),
  //   hidden: true,
  // },
  {
    path: '/console/centerManagement/viewAllSchedule',
    redirect: {
      name: 'center-all-consultant-schedule-day',
    },
    name: 'center-all-consultant-schedule',
    component: () => import('@/views/console/centerManagement/viewAllSchedule/index.vue'),
    hidden: true,
    children: [
      {
        path: 'day',
        name: 'center-all-consultant-schedule-day',
        component: () => import('@/views/console/centerManagement/viewAllSchedule/day/index.vue'),
      },
      {
        path: 'week',
        name: 'center-all-consultant-schedule-week',
        component: () => import('@/views/console/centerManagement/viewAllSchedule/week/index.vue'),
      },
    ],
  },
  {
    path: '/console/memberClassRecords',
    name: 'center-member-class-records',
    component: () => import('@/views/console/memberClassRecords/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/memberClassRecords/viewAnalysis',
    name: 'center-view-analysis',
    component: () => import('@/views/console/memberClassRecords/viewAnalysis/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/memberClassRecords/addAnalysis',
    name: 'center-add-analysis',
    component: () => import('@/views/console/memberClassRecords/addAnalysis/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/memberClassRecords/viewEvaluation/:evaluationId',
    name: 'center-view-evaluation',
    component: () => import('@/views/console/memberClassRecords/viewEvaluation/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/consultant/classRecords',
    name: 'consultant-class-records',
    component: () => import(
      '@/views/console/consultant/classRecords/index.vue'
    ),
    children: [], hidden: true,
  },
  {
    path: '/console/consultant/classRecords/viewAnalysis',
    name: 'consultant-view-analysis',
    component: () => import('@/views/console/consultant/classRecords/viewAnalysis/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/consultant/classRecords/addAnalysis/:classroomId',
    name: 'consultant-add-analysis',
    component: () => import('@/views/console/consultant/classRecords/addAnalysis/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/consultant/classRecords/viewEvaluation',
    name: 'consultant-view-evaluation',
    component: () => import('@/views/console/consultant/classRecords/viewEvaluation/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/consultant/vip-records/class',
    name: 'consultant-vip-records',
    component: () => import('@/views/console/consultant/vipRecords/class/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/consultant/vip-records/performance',
    name: 'consultant-vip-performance',
    component: () => import('@/views/console/consultant/vipRecords/performance/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/classRecords',
    name: 'center-class-records',
    component: () => import('@/views/console/memberClassRecords/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/classRecords/viewAnalysis',
    name: 'center-class-records-view-analysis',
    component: () => import('@/views/console/memberClassRecords/viewAnalysis/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/console/classRecords/addAnalysis',
    name: 'center-class-records-add-analysis',
    component: () => import('@/views/console/memberClassRecords/addAnalysis/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/dojo/contact-us',
    name: 'contact-us',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/contactUs/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/dojo/program-contract',
    name: 'program-contract',
    beforeEnter: checkGetToken,
    component: () => import('@/views/dojo/programContract/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/dojo/contactUs/index.vue'),
    children: [],
    hidden: true,
  },
  {
    path: '/fake-login/you-dont-want-to-know-ha-ha/:token',
    name: 'fake-login-with-token',
    component: () => import('@/views/login/fakeLogin/index.vue'),
    beforeEnter: async(to, from, next) => {
      if (to.params.token) {
        localStorage.setItem('wuwow_token', to.params.token);
        const accountAuthority = await lioshutanApi.vip.getAccountAuthority();
        const accountRole = accountAuthority.data.data.role;
        localStorage.setItem('account_role', accountRole);
      }
      next();
    },
    hidden: true,
  },
  // 2025landing Page
  {
    path: '/event/wuwowjr_2025_opening',
    name: 'Opening2025',
    component: () => import('@/views/event/wuwowjr_2025_opening.vue'),
  },
  // landing Page
  {
    path: '/event/regular_cycle_myth_englearning',
    name: 'regular-cycle-myth-englearning',
    component: () => import('@/views/event/regular-cycle-myth-englearning.vue'),
  },
  // 對應感謝頁
  {
    path: '/event/regular_cycle_myth_englearning/thank-you',
    name: 'regular-cycle-myth-englearning-thank-you',
    component: () => import('@/views/home/thankYou/index.vue'),
  },
  {
    path: '/event/regular_cycle_myth_englearning_agency',
    name: 'regular-cycle-myth-englearning-agency',
    component: () => import('@/views/event/regular-cycle-myth-englearning-agency.vue'),
  },
  {
    path: '/event/regular_cycle_myth_englearning_agency/thank-you',
    name: 'regular-cycle-myth-englearning-agency-thank-you',
    component: () => import('@/views/home/thankYou/index.vue'),
  },
  {
    path: '/event/trial',
    name: 'event-trial',
    component: () => import('@/views/event/trial/index.vue'),
  },
  {
    path: '/event/trial/cross_industry',
    name: 'event-trial-cross-industry',
    component: () => import('@/views/event/trial/crossIndustry/index.vue'),
  },
  {
    path: '/event/childrensday_2024',
    name: 'childrensday2024',
    component: () => import('@/views/event/childrensday2024/index.vue'),
  },
  {
    path: '/event/childrensday_2024/thank_you',
    name: 'childrensday2024ThankYou',
    component: () => import('@/views/home/thankYou/childrensday2024/index.vue'),
  },
  {
    path: '/event/childrensday_2024/ispot_thank_you',
    name: 'childrensday2024ispotThankYou',
    component: () => import('@/views/home/thankYou/childrensday2024/index.vue'),
  },
  {
    path: '/event/childrensday_2024/eric_thank_you',
    name: 'childrensday2024ericThankYou',
    component: () => import('@/views/home/thankYou/childrensday2024/index.vue'),
  },
  {
    path: '/event/caves',
    name: 'event-caves-education-authorize',
    component: () => import('@/views/event/cavesEducationAuthorize/index.vue'),
  },
  {
    path: '/event/newyear_2024',
    name: 'event-newyear-2024',
    component: () => import('@/views/event/newYear2024/index.vue'),
  },
  {
    path: '/event/newyear_2024/thank_you',
    name: 'newyear-thank',
    component: () => import('@/views/home/thankYou/newyear2024/index.vue'),
  },
  {
    path: '/event/newyear_2024/eric_thank_you',
    name: 'newyear-eric-thank',
    component: () => import('@/views/home/thankYou/newyear2024/index.vue'),
  },
  {
    path: '/event/anniversary_2023',
    name: 'event-anniversary-celebrate-2023',
    component: () => import('@/views/event/anniversary2023/index.vue'),
  },
  {
    path: '/event/anniversary_2023/thank_you',
    name: 'anniversary-thank',
    component: () => import('@/views/home/thankYou/anniversary2023/index.vue'),
  },
  {
    path: '/event/anniversary_2023/eric_thank_you',
    name: 'anniversary-eric-thank',
    component: () => import('@/views/home/thankYou/anniversary2023/index.vue'),
  },
  {
    path: '/event/psychological_test_dizney_characters',
    name: 'psychological-test-dizney-characters',
    component: () => import('@/views/event/psychological_test_dizney_characters/index.vue'),
  },
  // {
  //   path: '/event/summer_camp_2023',
  //   name: 'summer-camp-2023',
  //   component: () => import('@/views/event/summerCamp2023/index.vue'),
  // },
  // {
  //   path: '/event/summer_camp_2023/thank_you',
  //   name: 'summer-camp-2023-thank-you',
  //   component: () => import('@/views/home/thankYou/summerCamp2023/index.vue'),
  // },
  // {
  //   path: '/event/summer_camp_2023/eric_thank_you',
  //   name: 'summer-camp-2023-eric-thank-you',
  //   component: () => import('@/views/home/thankYou/summerCamp2023/index.vue'),
  // },
  // 敦煌合作
  {
    path: '/event/caves/eric_thank_you',
    name: 'caves-eric-thank-you',
    component: () => import('@/views/home/thankYou/cavesEducationAuthorize/index.vue'),
  },
  {
    path: '/event/caves/thank_you',
    name: 'caves-thank-you',
    component: () => import('@/views/home/thankYou/cavesEducationAuthorize/index.vue'),
  },
  {
    path: '/event/trial/thank-you',
    name: 'trial-thank-you',
    component: () => import('@/views/home/thankYou/trial/index.vue'),
  },
  {
    path: '/event/trial/eric_thank_you',
    name: 'trial-eric-thank-you',
    component: () => import('@/views/home/thankYou/trial/index.vue'),
  },
  {
    path: '/event/trial/ispot_thank_you',
    name: 'trial-ispot-thank-you',
    component: () => import('@/views/home/thankYou/trial/index.vue'),
  },
  {
    path: '/event/trial/cross_industry/thank_you',
    name: 'trial-cross-industry-thank-you',
    component: () => import('@/views/home/thankYou/trial/crossIndustry/index.vue'),
  },
  // 直接下載贈品用感謝頁，
  {
    path: '/event/trial/event/lazy_package/thank_you',
    name: 'download-thank-you',
    component: () => import('@/views/home/thankYou/download/index.vue'),
  },
  // 金舶獎新聞頁
  {
    path: '/news/golden-ship-award',
    name: 'golden-ship-award',
    component: () => import('@/views/news/golden-ship-award/index.vue'),
  },
  { path: '*', redirect: '/404', hidden: false },
  // 六書堂與敦煌合作新聞頁
  {
    path: '/news/collaboration-with-caves-education',
    name: 'collaboration-with-caves-education',
    component: () => import('@/views/news/collaboration-with-caves-education/index.vue'),
  },
  { path: '*', redirect: '/404', hidden: false },
];
